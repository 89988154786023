



import Vue from "vue";
import { BroadcastChannel } from "broadcast-channel";

/**
 * parses contents from current url and sends it as broadcast message
 * directly referenced as route. is expected to get opened and closed automatically from another tab.
 */
export default Vue.extend({
    name: "BroadcastChannel",
    components: {},
    async mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const channelName = urlParams.get("channel");
        const msg = urlParams.get("msg");

        if (channelName && msg) {
            const channel = new BroadcastChannel(channelName);
            await channel.postMessage(msg);
        }
    }
});
